// Variables
@import "../../../sass/variables";

// メンテナンスモード時の表示
.maintenance {
  position: fixed;
  top: 145px;
  z-index: 9999;
  padding: 0.2rem 0.5rem;
  background-color: red;
  color: #fff;
}

.footer-sp {
  display: none;
}

.sub-footer-row {
  padding: 0;
  color: white;
  background-color: #2e2d2d;

  ul {
    padding-left: 5px;

    li {
      a {
        color: white;
        font-size: 12px;
      }
    }
  }

  .feature-column {
    flex-wrap: wrap;

    li {
      width: 50%;
    }
  }
}

.copy-right-row {
  background-color: $theme-main-black;
}

/////////////////////////////////////////////////
/////////////// スマートフォン対応 ///////////////
@media (max-width: 767px) {
  .footer-pc {
    display: none;
  }

  .footer-sp {
    text-align: center;
    display: block !important;

    .sub-footer-row {
      padding: 15px 0 10px;

      .accordion {
        .accordion-title {
          margin-bottom: 10px;
          display: inline-block;
          padding: 0 20px;
          background-image: url("/assets/icons/arrow_r_white.svg");
          background-repeat: no-repeat;
          background-position: right;
          background-size: 11px;
        }

        .accordion-title.active {
          background-image: url("/assets/icons/arrow_d_white.svg");
        }

        ul {
          margin-bottom: 0;

          li {
            a {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}